import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSheet,{staticClass:"pa-1",staticStyle:{"box-shadow":"none","outline":"none"},attrs:{"outlined":_vm.outlined,"rounded":""}},[(_vm.config.showDefaultText && !_vm.value)?_c('div',{staticClass:"grey--text pa-1",style:([_vm.inputStyle, {cursor: 'pointer'}]),attrs:{"contenteditable":"false"},domProps:{"textContent":_vm._s(_vm.defaultText)},on:{"click":_vm.defaultTextClick}}):_c('div',{ref:"commentInput",staticClass:"pa-1",style:(_vm.inputStyle),attrs:{"id":"commentInput","contenteditable":"true"},domProps:{"innerHTML":_vm._s(_vm.value)},on:{"input":_vm.onInput,"keydown":_vm.onKeyDown,"blur":_vm.onBlur}})]),(_vm.showSave)?_c('div',{staticClass:"py-3 d-flex justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1):_vm._e(),_c(VMenu,{attrs:{"rounded":"lg","position-x":_vm.config.caret.x + 5,"position-y":_vm.config.caret.y,"absolute":"","ripple":"","offset-y":""},model:{value:(_vm.config.showMenu),callback:function ($$v) {_vm.$set(_vm.config, "showMenu", $$v)},expression:"config.showMenu"}},[_c(VCard,{attrs:{"tile":""}},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.config.menuSelection),callback:function ($$v) {_vm.$set(_vm.config, "menuSelection", $$v)},expression:"config.menuSelection"}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.clickMention(item)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.name)}})],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }