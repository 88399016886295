import axios from 'axios';

export default function jiraHelper() {

    const loadAttachment = async (attachmentId) => {
        /* 
        TODO: consolidate this code snippet to a common place 
        A component JiraAttachmentLink would be nice, but 
        tricky in this case because the a links are being rendered
        by server
        */
        const response = await axios.get(`/jira/get_attachment/${attachmentId}/`, {responseType: 'blob'});
        if (!window.navigator.msSaveOrOpenBlob){
            const contentDisposition = response.headers['content-disposition'];
            
            const url = URL.createObjectURL(new Blob([response.data],{type:response.headers['content-type']}));
            const link = document.createElement('a');
            const fileName = contentDisposition.match(/filename=(?<filename>[^,;]+)/)[1];
            
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert('Fail: what version of IE are you using?');
        }
        /* End TODO */
    };

    return {
        loadAttachment
    }
}