<template>
    <v-row>
        <v-col>
            <!--<div class="overline">Building</div>-->
            <v-select
                label="Building"
                item-text="name"
                item-value="id"
                ref="selectBuilding"
                :value="location.building"
                :items="buildingList"
                :rules="$rules.required"
                :hide-details="hideDetails"
                :dense="dense"
                :outlined="outlined"
                @change="chgBuilding"
                return-object
            />
        </v-col>
        <template v-if="!config.remote">
            <v-col>
                <template v-if="config.floorList.length">
                    <v-select
                        label="Floor"
                        ref="selectFloorList"
                        :value="location.floor"
                        :items="config.floorList"
                        :menu-props="config.floorListMenuProps"
                        :rules="$rules.required"
                        :hide-details="hideDetails"
                        :dense="dense"
                        :outlined="outlined"
                        @change="chgFloor"
                    />
                </template>
                <template v-else>
                    <v-text-field
                        label="Floor"
                        :value="location.floor"
                        :rules="$rules.required"
                        :hide-details="hideDetails"
                        :dense="dense"
                        :outlined="outlined"
                        @change="chgFloor"
                    />
                </template>
            </v-col>
            <v-col>
                <v-text-field
                    label="Room"
                    v-model="location.room"
                    :rules="$rules.required"
                    :hide-details="hideDetails"
                    :dense="dense"
                    :outlined="outlined"
                />
            </v-col>
            <v-expand-transition>
                <div
                    v-show="cartography && config.floorPlan && config.floorPlan.url"
                    style="width: 100%; margin: 5px 15px;"
                >
                    <slot name="alert" :icon="config.icon" :alertType="alertType"></slot>
                    <div
                        style="height: 500px; width: 100%;"
                        :class="{'error-outline shake rounded': config.annotationNeedsMove}"
                    >
                    
                        <div
                            class="fill-height"
                            ref="pdfCon"
                            :id="containerId"
                        />
                    </div>
                </div>
            </v-expand-transition>
        </template>
        <template v-else>
            <v-col cols="12">
                <v-text-field
                    label="Address"
                    v-model="location.address"
                    :rules="$rules.required"
                    :hide-details="hideDetails"
                    :dense="dense"
                    :outlined="outlined"
                    :autofocus="location.building && location.building.name === 'Remote / Other'"
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Apt / Suite / Other"
                    v-model="location.address2"
                    :hide-details="hideDetails"
                    :dense="dense"
                    :outlined="outlined"
                />
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="City"
                    v-model="location.city"
                    :rules="$rules.required"
                    :hide-details="hideDetails"
                    :dense="dense"
                    :outlined="outlined"
                />
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="State"
                    v-model="location.state"
                    :rules="$rules.required"
                    :hide-details="hideDetails"
                    :dense="dense"
                    :outlined="outlined"
                />
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Zip"
                    v-model="location.zip_code"
                    :rules="$rules.required"
                    :hide-details="hideDetails"
                    :dense="dense"
                    :outlined="outlined"
                />
            </v-col>
        </template>
    </v-row>
</template>
<script>

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// import PSPDFKit from 'pspdfkit';

import { computed, onMounted, set, watch, onBeforeUnmount } from '@vue/composition-api';

import locationHelper from "@/composables/space/locationHelper";

export default {
    name: 'LocationWidget',
    components: {},
    props: {
        hideDetails: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        cartography: {
            type: Boolean,
            default: true
        },
        userInitials: {
            type: String,
            default: null
        },
        value: {
            type: Object,
            default() {
                return {};
            }
        },
        pdfContainerId: {
            type: String,
            default: null
        }
    },
    emits: ['resetValidation', 'input'],
    setup(props, context) {
    
        const store = context.root.$store;

        let pspdfInstance;

        const {
            loadPDF,
            unloadPDF,
            addAnnotation,
            location,
            config,
            resetLocation
        } = locationHelper(context);
        
        const buildingList = computed(() => store.getters['space/getBuildingList']);

        const containerId = props.pdfContainerId || 'pspdfkit-' + uuidv4();

        const alertType = computed(() => config.annotationNeedsMove ? 'error' : 'info' );

        onMounted(async () => {
            
            if (!buildingList.value.length) await store.dispatch('space/loadBuildings');

            context.root.$nextTick(() => {
                if (Object.keys(props.value).length && Object.keys(props.value.building).length) {
                    if (props.value.annotation) {
                        config.annotationMoved = true;
                        config.annotationNeedsMove = false;
                    }
                    reloadWithLocation(props.value, {select: false});
                }
            });
        });


        onBeforeUnmount(() => {
            if (context.refs.pdfCon !== undefined) unloadPDF(context.refs.pdfCon);
        });

        const loadBuildingFloor = async (bldgId, floor=null) => {

            let url = `/space/load_building_floor_plans/${bldgId}/`;

            if (floor) url+= `?floor=${floor}`;

            const response = await axios.get(url);

            config.floorPlan = response.data.floor_plan;
            config.floorList = response.data.floors;

            context.emit('resetValidation');
        };


        const chgBuilding = async (val) => {

            resetLocation();

            location.value.building = val;

            if (val.name === 'Remote / Other') {
                config.remote = true;
                context.refs.selectBuilding.blur();
                return
            }

            await loadBuildingFloor(val.id);

            if (config.floorList.length === 1) {

                return chgFloor(config.floorList[0], false);

            } else if (config.floorList.length > 1) {

                context.root.$nextTick(() => {

                    config.floorListMenuProps = {value: true};
                    context.refs.selectBuilding.blur();
                    context.refs.selectFloorList.focus();
                    
                });
            }
        };


        const chgFloor = async (val, loadBldgFlr=true) => {
            set(location.value, 'floor', val);
            config.floorListMenuProps = 'auto';

            if (loadBldgFlr) await loadBuildingFloor(location.value.building.id, val);
            pspdfInstance = await loadPDF(pspdfInstance, config.floorPlan.url, `#${containerId}`);

            addAnnotation(pspdfInstance, null, props.userInitials);
        };


        const reloadWithLocation = async (loc, annotOpts={}) => {
            location.value = loc;
            if (loc.building.name === 'Remote / Other') {
                config.remote = true;
                return;
            }

            await loadBuildingFloor(loc.building.id, loc.floor);
            pspdfInstance = await loadPDF(pspdfInstance, config.floorPlan.url, `#${containerId}`);

            if (loc.annotation) {
                addAnnotation(pspdfInstance, loc.annotation.bbox, props.userInitials, annotOpts);
                config.annotationMoved = true;
                config.annotationNeedsMove = false;
            } else {
                addAnnotation(pspdfInstance, null, props.userInitials);
                config.annotationMoved = false;
            }
        };
        
        
        watch(() => location.value, (newVal) => {
            context.emit('input', newVal);
        }, {deep: true});


        const validate = () => {
            if (!config.remote && props.cartography && !config.annotationMoved) {
                config.annotationNeedsMove = true;
                return false;
            }

            return true;
        };


        return {
            config,
            location,
            buildingList,
            chgBuilding,
            chgFloor,
            validate,
            reloadWithLocation,
            containerId,
            alertType
        }
    },
};
</script>