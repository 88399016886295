<template>
    <div>
        <v-sheet
            class="pa-1"
            style="box-shadow: none; outline: none"
            :outlined="outlined"
            rounded
        >
            <!-- [inputStyle, 'cursor: pointer'] -->
            <div
                v-if="config.showDefaultText && !value"
                contenteditable="false"
                class="grey--text pa-1"
                :style="[inputStyle, {cursor: 'pointer'}]"
                v-text="defaultText"
                @click="defaultTextClick"
            />
            
            <div
                v-else
                id="commentInput"
                ref="commentInput"
                contenteditable="true"
                class="pa-1"
                v-html="value"
                :style="inputStyle"
                @input="onInput"
                @keydown="onKeyDown"
                @blur="onBlur"
            />
        </v-sheet>
        <div
            v-if="showSave"
            class="py-3 d-flex justify-end"
        >
            <v-btn
                class="mr-2"
                @click="$emit('cancel')"
                small
            >Cancel</v-btn>
            <v-btn
                color="primary"
                :disabled="!isValid"
                @click="save"
                small
            >
                Save Changes
            </v-btn>
        </div>
        <v-menu
            v-model="config.showMenu"
            rounded="lg"
            :position-x="config.caret.x + 5"
            :position-y="config.caret.y"
            absolute
            ripple
            offset-y
        >
            <v-card tile>
                <v-list
                    dense
                >
                    <v-list-item-group
                        color="primary"
                        v-model="config.menuSelection"
                    >
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="clickMention(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-html="item.name" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>
<script>

import axios from 'axios';

import { reactive, ref, set, watch, onMounted } from '@vue/composition-api';

export default {
    name: 'MentionWidget',
    props: {
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        limit: {
            type: Number,
            default: 5
        },
        outlined: {
            type: Boolean,
            default: true
        },
        defaultText: {
            type: String,
            default: 'Enter a new comment'
        },
        height: {
            type: String,
            default: null
        },
        maxHeight: {
            type: String,
            default: '180px'
        },
        value: {
            type: String,
            default: null
        },
        showSave: {
            type: Boolean,
            default: false
        },
        preloadMentions: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    emits: ['valid', 'save', 'cancel'],
    setup(props, { refs, root, emit }) {

        const config = reactive({
            showMenu: false,
            menuSelection: 0,
            selectedItem: null,
            selection: null,
            range: null,
            selectionOffset: 0,
            caret: {
                x: 0,
                y: 0
            },
            showDefaultText: true,
        });

        const inputStyle = {
            overflow: 'auto',
            maxHeight: props.maxHeight,
            height: props.height ? props.height + 'px' : 'auto',
            userSelect: 'text'
        };
        
        const isValid = ref(false);

        const commentText = ref(null);

        const items = ref([]);

        const mentions = ref({});

        // const isMatchingRegex = /(?<!<mark.+">)@([\w\d]+)/m;
        const isMatchingRegex = /(?!.*<\/mark>)@([\w\d]+)/m;

        const searchTerm = ref(null);

        const onInput = (e) => {
            const comment = commentText.value = e.target.innerHTML;
            
            isValid.value = !!e.target.innerText.trim().length;

            config.selection = window.getSelection();
            config.range = config.selection.getRangeAt(0).cloneRange()
            config.caret = getCaretCoordinates();

            const match = comment.match(isMatchingRegex);

            if (match) {
                searchUsers(match[1]);
                searchTerm.value = match;
                config.showMenu = true;
            } else {
                config.showMenu = false;
            }
        };

        const onKeyDown = (e) => {
            switch (e.key) {
                case 'Enter': {
                    if (config.showMenu) {
                        if (config.menuSelection !== undefined) {
                            clickMention(items.value[config.menuSelection]);
                        }
                        e.preventDefault();
                    }
                    break;
                }
                case 'Backspace': {
                    const sel = window.getSelection();
                    if (sel && sel.anchorOffset === 0 && sel.anchorNode.previousSibling && sel.anchorNode.previousSibling.tagName === 'MARK') {
                        sel.anchorNode.previousSibling.parentNode.removeChild(sel.anchorNode.previousSibling)
                    }
                    break;
                }
                case 'ArrowUp':
                case 'ArrowDown': {
                    if (config.showMenu) {
                        if (items.value.length) {
                            let cur = config.menuSelection || 0;
                            e.key === 'ArrowUp' ? cur-- : cur++;
                            config.menuSelection = Math.min(Math.max(cur, 0), items.value.length - 1);
                        }
                        e.preventDefault();
                    }
                    break;
                }
            }
        };

        const getCaretCoordinates = () => {
            let x = 0, y = 0;

            const sel = config.selection;

            if (sel.rangeCount) {
                //const range = config.range = selection.getRangeAt(0).cloneRange();
                const range = config.range;
                range.collapse(true);
                const rect = range.getClientRects()[0];
                if (rect) {
                    x = rect.left;
                    y = rect.top;
                }
            }

            return { x, y };
        };

        const searchUsers = async (term) => {
            const response = await axios.get(`/population/search_people/?q=${term}&users_only=1&ldap_populate=1&limit=${props.limit}`);
            items.value = response.data.people;
        };


        const clickMention = (user) => {
            const mentionUser = `@${user.first_name} ${user.last_name}`;

            const sel = config.selection;

            let range = config.range;


            // safari fix
            refs.commentInput.focus();

            const offset = range.startOffset;

            range.setStart(range.startContainer, range.startOffset);
            range.collapse(true)
            sel.removeAllRanges();
            sel.addRange(range);

            if (sel.rangeCount) {

                range.setStart(range.startContainer, offset - searchTerm.value[0].length);
                range.deleteContents();

                let mentionEl = document.createElement('mark');
                mentionEl.setAttribute('spellcheck', 'false');
                mentionEl.setAttribute('contenteditable', 'false');
                mentionEl.textContent = mentionUser;
                const space = document.createTextNode('\u00A0');

                range.insertNode(mentionEl);
                range.setStartAfter(mentionEl);
                range.insertNode(space);
                range.setStartAfter(space);

                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);

                set(mentions.value, mentionUser, {username: user.username})

            }

            config.showMenu = false;
            set(config, 'menuSelection', 0);
            searchTerm.value = null;
        };

        const defaultTextClick = () => {
            config.showDefaultText = false
            root.$nextTick(() => {
                refs.commentInput.focus();
            });
        };

        const onBlur = () => {
            if (!isValid.value) {
                config.showDefaultText = true;
                const sel = window.getSelection && window.getSelection();
                sel.removeAllRanges();
            }
        };

        watch(isValid, (val) => emit('valid', val));

        const save = () => {
            emit('save', getComment());
        };

        onMounted(() => {
            if (props.value) isValid.value = true;
            mentions.value = props.preloadMentions;
        });


        // EXTERNALLY CALLED
        const clear = () => {
            if (refs.commentInput) refs.commentInput.innerHTML = '';
            commentText.value = null;
            config.showDefaultText = true;
            mentions.value = {};
            isValid.value = false;
        };

        const getComment = () => {
            if (!refs.commentInput || !refs.commentInput.innerText) return ''

            let body = refs.commentInput.innerText;
        
            for (const [k, v] of Object.entries(mentions.value)) {
                body = body.replace(k, `[~${v.username}]`);
            }
            return body;
        };
        

        const focus = () => {
            refs.commentInput.focus();
        };

        return {
            config,
            commentText,
            items,
            onInput,
            onKeyDown,
            clickMention,
            searchUsers,
            defaultTextClick,
            onBlur,
            clear,
            getComment,
            focus,
            save,
            isValid,
            mentions,
            inputStyle
        }
    }
};
</script>
<style scoped>
#commentInput:read-write:focus {
    outline: none;
}
::v-deep mark {
    padding: 4px;
    border-radius: 4px;
    color: #fff;
    background-color: #5cbbf6;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>