<template>
    <div
        style="max-height: 500px; overflow: auto; scrollbar-width: none;"
        class="pa-1"
    >
        <template
            v-for="(comment, i) in comments"
        >
            <v-row
                no-gutters
                :key="`c${i}`"
            >
                <v-col
                    class="text-h6 font-weight-light grey--text"
                    cols="6"
                >
                    <slot
                        name="author"
                        :comment="comment"
                    />
                </v-col>
                <v-col
                    cols="6"
                    class="caption grey--text d-flex align-center justify-end"
                    :title="comment[createdValue]"
                >
                    {{ getDateDistanceIso(comment[createdValue], {addSuffix: true}) }}
                    <slot
                        name="action"
                        :comment="comment"
                    />
                </v-col>
                <v-col cols="12"
                    style="white-space: pre-line"
                    class="body-2"
                >
                    <slot
                        name="comment"
                        :comment="comment"
                    >
                        <div v-html="comment[commentValue]" />
                    </slot>
                </v-col>
            </v-row>
            <v-divider
                v-if="i < comments.length - 1"
                class="my-6"
                :key="`cDiv${i}`"
            />
        </template>
    </div>
</template>
<script>
// import { reactive, ref, set, watch } from '@vue/composition-api';

import dateTimeHelper from '@/composables/common/dateTime';


export default {
    name: 'CommentList',
    props: {
        comments: {
            type: Array,
            default() {
                return []
            }
        },
        createdValue: {
            type: String
        },
        commentValue: {
            type: String
        }
    },
    setup() {
        const { getDateDistanceIso } = dateTimeHelper();

        return {
            getDateDistanceIso
        }
    }
};
</script>