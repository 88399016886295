import { ref, reactive, set } from '@vue/composition-api';
import PSPDFKit from 'pspdfkit';

export default function locationHelper({ root }) {

    const location = ref({});
    
    const initConfig = {
        floorList: [],
        floorPlan: {},
        floorListMenuProps: 'auto',
        remote: false,
        annotationMoved: false,
        annotationNeedsMove: false,
        icon: null
    };
    const config = reactive({...initConfig});

    const resetLocation = () => {
        Object.assign(config, JSON.parse(JSON.stringify(initConfig)));
        location.value = {};
    };

    const unloadPDF = (container='#pspdfkit') => {
        PSPDFKit.unload(container);
    };

    // let pspdfInstance;

    const loadPDF = async (pspdfInstance, url, container='#pspdfkit') => {
    
        unloadPDF(container);

        pspdfInstance = await PSPDFKit.load({
            locale: 'en',
            baseUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_BASEURL : process.env.VUE_APP_PSPDFKIT_BASEURL_DEV,
            container: container,
            toolbarItems: [
                { type: 'pan' },
                { type: 'zoom-out' },
                { type: 'zoom-in' },
                { type: 'zoom-mode' },
            ],
            styleSheets: [`${root.baseUrl}/static/css/pspdfkit.css`,],
            document: url,
            licenseKey: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_LICENSE_KEY : process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_DEV,
        });
        
        
        pspdfInstance.addEventListener('annotations.update', async () => {
            const instantJSON = await pspdfInstance.exportInstantJSON();
            config.annotationMoved = true;
            config.annotationNeedsMove = false;
        
            set(location.value, 'annotation', {
                bbox: instantJSON.annotations[0].bbox,
                floor_plan: {
                    id: config.floorPlan.id,
                    url: config.floorPlan.url
                }
            });
        });

        return pspdfInstance;
    };
    
    
    const addAnnotation = async (pspdfInstance, bbox=null, initials=null, opts={}) => {
        
        if (!pspdfInstance) return;
        opts.select = typeof(opts.select) === 'undefined' ? true : opts.select;

        if (!bbox) {
            const pgInfo = pspdfInstance.pageInfoForIndex(0);
            bbox = [Math.round(pgInfo.width / 2), Math.round(pgInfo.height / 2), 20, 20]; // [left, top, width, height]
        }

        let icon;
        icon = config.icon = initials ? root.drawCircle(initials) : root.drawCircle('', {color: 'red'});
        
        let file = await fetch(icon);
        file = await file.blob();
        
        const imgAttachmentId = await pspdfInstance.createAttachment(file);
        
        const imgAnnotation = new PSPDFKit.Annotations.ImageAnnotation({
            imageAttachmentId: imgAttachmentId,
            pageIndex: 0,
            name: '',
            contentType: 'image/png',
            customData: {},
            boundingBox: new PSPDFKit.Geometry.Rect({
                width: bbox[2],
                height: bbox[3],
                top: bbox[1],
                left: bbox[0]
            })
        });
        
        const annotation = await pspdfInstance.create(imgAnnotation);

        
        pspdfInstance.addEventListener('annotations.delete', async () => {
            await pspdfInstance.create(imgAnnotation);
        });
        
        
        root.$nextTick(() => {
            
            try {
                if (opts.select) pspdfInstance.setSelectedAnnotation(annotation[0].id);
            } catch {
                root.$nextTick(async () => {
                    try {
                        pspdfInstance.setSelectedAnnotation(annotation[0].id);
                    } catch {
                        console.log('could not select annotation, skipping...');
                        return false;
                    }
                });
            }
        });

        return annotation;
    };

    return {
        addAnnotation,
        unloadPDF,
        loadPDF,
        resetLocation,
        location,
        config,
        initConfig
    }
}