export default function attachmentHelper() {

    const attachmentIcon = (f) => {
        const ext = f.split('.').pop().toLowerCase();
        switch (ext) {
            case 'doc':
            case 'docx':
                return 'mdi-file-word';
            case 'pdf':
                return 'mdi-file-pdf';
            case 'xlsx':
            case 'xls':
                return 'mdi-file-excel';
            case 'ppt':
            case 'pptx':
                return 'mdi-file-powerpoint';
            case 'jpg':
            case 'gif':
            case 'png':
                return 'mdi-file-image';
            default:
                return 'mdi-file';
        }
    };

    return {
        attachmentIcon
    }
}